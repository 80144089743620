import { isTouchDevice } from "common/utils/browser";
import { writeCookie } from "common/utils/cookie";
import { track } from "jiffy-analytics";
import { api } from "jiffy-api";

import "./guest-entry-modal.scss";

const COOKIE_NAME = "guest_entry_modal";

const $getModal = () => document.getElementById("js-guest-entry-modal");
const $getModalBackdrop = () => document.getElementById("js-guest-entry-modal-backdrop");

const handleModalClose = () => {
  $getModal().remove();
  $getModalBackdrop().remove();

  writeCookie("masthead-account-popup", JSON.stringify({ executed: true })); // Do not show for an hour masthead-account-popup too
  writeCookie(COOKIE_NAME, "true", 2147483647); // 2147483647 - https://stackoverflow.com/questions/532635/javascript-cookie-with-no-expiration-date/
};

const initMixpanelDeclarativeEvents = ($modal, isMobileApp) => {
  $modal.querySelectorAll("[data-mixpanel-event]").forEach(el => {
    const [action, target] = el.dataset.mixpanelEvent.split(":");

    el.addEventListener("click", () => {
      track(action, { target, isMobileApp });
    });
  });
};

const initMixpanelModalEvents = ($modal, isMobileApp) => {
  $($modal)
    .on("show.bs.modal", () => {
      track("guest_site_entry_popup_opened", {
        isMobileApp,
        version: "v2"
      });
    })
    .on("hide.bs.modal", () => {
      track("guest_site_entry_popup_closed", { isMobileApp });
    });
};

const initMixpanelTracking = $modal => {
  const { isMobileApp } = $modal.dataset;

  initMixpanelDeclarativeEvents($modal, isMobileApp);
  initMixpanelModalEvents($modal, isMobileApp);
};

const handleAddCookie = () => {
  const $links = document.querySelectorAll(".js-guest-entry-modal-link");

  $links.forEach($link => {
    $link.addEventListener("click", () => $($getModal()).modal("hide"));
  });
};

const initGuestEntryModal = async () => {
  const { hashed_id: currentUserId } = await api.user.current();
  if (currentUserId) return;

  const $modal = $getModal();
  if (!$modal) return;

  const $$modal = $($modal);

  initMixpanelTracking($modal);
  $$modal.on("hide.bs.modal", handleModalClose);

  if ($modal.classList.contains("hide")) $modal.classList.remove("hide");
  $$modal.modal("show");

  const $modalBackdrop = $getModalBackdrop();
  if ($modalBackdrop.classList.contains("hide")) $modalBackdrop.classList.remove("hide");

  if (!isTouchDevice()) {
    $modal.querySelector(".js-guest-entry-modal-link")?.focus();
  }

  handleAddCookie();
};

export default initGuestEntryModal;
